<template>

  <div id="loader" v-if="pendingParticipationSent">
    <div class="loader-message">
      <div class="loader-visual">&nbsp;</div>
      <div>{{$t('pageTitles.wait')}}</div>
    </div>
  </div>

  <div id="formsContainer">

    <!-- EMPTY CONTAINER REQUIRED BY GOOGLE MAP -->
    <div ref="map"></div>

    <!-- DISPLAY MESSAGES (here and not in App.vue, because it's form.vue that receive the errors) -->
    <toaster	 :class="{'active':isThereMessage}" :msgType="messageType" @closeMe="isThereMessage = false;">
      <template v-slot:text>
        <div class="modaleTitle">{{messageTitle}}</div>
        <ul>
          <li v-for="(message, index) in messageContent" :key="index">&rtrif; {{message}}</li>
        </ul>
        <div class="modaleConclusion">{{finalMessageContent}}</div>
      </template>
    </toaster>


    <!-- DIFFERENT "PAGES" OF THE FORM -->
    <transition name="fade" mode="out-in">









      <page-form :title="$t('pageTitles.wait')" :grid="false" v-if="currentPage==='loading' || !allowFormToBeDisplayed" key="loading">
        <template v-slot:content>
          <!-- ================================================ -->
          <section>
            <!-- <div style="text-align: center; margin:2em;">Ajouter un petit spinner ici ?</div> -->
          </section>
          <!-- ================================================ -->
        </template>
      </page-form>







      <page-form :title="$t('pageTitles.pageError')" :grid="false" v-else-if="currentPage==='pageError'" key="pageError">
        <template v-slot:content>
          <!-- ================================================ -->
          <section>
            <div class="simpletext">
              <p>
                {{$t('pageLitteracy.pageError.intro')}}
                <br>{{$t('pageLitteracy.pageError.solution1')}}
              </p>
              <p v-html="$t('pageLitteracy.pageError.solution2')"></p>
            </div>
          </section>
          <!-- ================================================ -->
        </template>
      </page-form>






      <page-form :title="$t('pageTitles.beforeStart')" v-else-if="currentPage==='beforeStart'" key="beforeStart">
        <template v-slot:content>
          <!-- ================================================ -->
          <section>
            <div class="simpletext">
              <!-- Si la date est dépassée, mais que le status de l'offre n'est toujours pas à "P" qu'afficher -->
              <!-- Uniquement en production -->

              <p v-if="config.isWebsiteInProduction && lead.offerstatus.toUpperCase() != 'P' &&  actionFullDates.registration.start.getTime() <= actionFullDates.current.getTime()">{{$t('pageLitteracy.beforeStart.shouldBeginSoon')}}
              </p>

              <p v-else>{{$t('pageLitteracy.beforeStart.text')}} <strong><span v-if="!config.isWebsiteInProduction">{{$t(getSimpleDate(addSixWeeksTo(actionFullDates.registration.start)))}}</span>
                <span v-else>{{$t(getSimpleDate(actionFullDates.registration.start))}}</span></strong>.
              </p>

            </div>
          </section>
          <!-- ================================================ -->
        </template>
      </page-form>





      <page-form :title="$t('pageTitles.actionEnded')" v-else-if="currentPage==='actionEnded'" key="actionEnded">
        <template v-slot:content>
          <!-- ================================================ -->
          <section>
            <div class="simpletext">
              <p>{{$t('pageLitteracy.actionEnded.text')}}</p>
            </div>
            <!-- NAV BUTTON TO REDIRECT TO AN OFFICIAL PAGE AFTER THE ACTION -->
            <!-- Only if such a link is non-empty -->
            <div class="centeredContent" v-if="!!$t('navigation.toCustomerWebsite.link')">
              <a class="fake_btn" :href="$t('navigation.toCustomerWebsite.link')">{{$t('navigation.toCustomerWebsite.label')}}</a>
            </div>
          </section>
          <!-- ================================================ -->
        </template>
      </page-form>








      <page-form :title="$t('pageTitles.maxReached')" v-else-if="currentPage==='maxReached'" key="maxReached">
        <template v-slot:content>
          <!-- ================================================ -->
          <section>
            <div class="simpletext">
              <p>{{$t('pageLitteracy.maxReached')}}</p>
            </div>
          </section>
          <!-- ================================================ -->
        </template>
      </page-form>







      <page-form :title="$t('pageTitles.instructions')" v-else-if="currentPage==='instructions'" key="instructions">
        <template v-slot:content>
          <!-- ================================================ -->


          <section>
            <div id="flexSteps">

              <!-- STEP INFO -->
              <div class="step">
                <icons-svg icon="info" class="icon"></icons-svg>
                <div class="instructionsDetails">
                  {{$t('pageLitteracy.instructions.steps.info')}}
                </div>
              </div>

              <!-- STEP REGISTRATION -->
              <div class="step">
                <icons-svg icon="registration" class="icon"></icons-svg>
                <div class="instructionsDetails">
                  {{$t('pageLitteracy.instructions.steps.register')}}
                </div>
              </div>

              <!-- STEP STORE -->
              <div class="step">
                <icons-svg icon="store" class="icon"></icons-svg>
                <div class="instructionsDetails">
                  <ul>
                    <li>{{$t('pageLitteracy.instructions.steps.store.line1a')}} <b>
                      <!-- ! Add six weeks ONLY in acceptance, NOT IN PROD -->
                      <span v-if="!config.isWebsiteInProduction">{{$t(getSimpleDate(addSixWeeksTo(actionFullDates.purchaseDates.start)))}}</span>
                      <span v-else>{{$t(getSimpleDate(actionFullDates.purchaseDates.start))}}</span>
                    </b> {{$t('pageLitteracy.instructions.steps.store.line1b')}} <b>{{$t(getSimpleDate(actionFullDates.purchaseDates.end))}}</b></li>
                    <li>{{$t('pageLitteracy.instructions.steps.store.line2')}}</li>
                    <li>{{$t('pageLitteracy.instructions.steps.store.line3')}}</li>
                  </ul>
                </div>
              </div>

              <!-- STEP FORM -->
              <div class="step">
                <icons-svg icon="form" class="icon"></icons-svg>
                <div class="instructionsDetails">
                  <ul>
                    <li>{{$t('pageLitteracy.instructions.steps.form.line1')}}</li>
                    <li>{{$t('pageLitteracy.instructions.steps.form.line2')}}</li>
                    <li>{{$t('pageLitteracy.instructions.steps.form.line3')}}</li>
                    <li>{{$t('pageLitteracy.instructions.steps.form.line4')}}</li>
                  </ul>
                </div>
              </div>

              <!-- STEP SURVEY -->
              <div class="step">
                <icons-svg icon="survey" class="icon"></icons-svg>
                <div class="instructionsDetails">
                  {{$t('pageLitteracy.instructions.steps.survey')}}
                </div>
              </div>

              <!-- STEP CASHBACK -->
              <div class="step">
                <icons-svg icon="cashback" class="icon"></icons-svg>
                <div class="instructionsDetails">
                  {{$t('pageLitteracy.instructions.steps.cashback')}}
                </div>

              </div>
            </div>

          </section>

          <!-- ================================================ -->
        </template>

        <template v-slot:nav >
          <section class="navigation" >
            <button @click.prevent="goto('registration');" v-if="registrationRequired">{{$t('navigation.signup')}}</button >
            <button @click.prevent="goto('form1');" v-else>{{$t('navigation.participate')}}</button>
          </section>

        </template>


      </page-form>









      <page-form :title="$t('pageTitles.landing')" v-else-if="currentPage==='landing'" key="landing">
        <template v-slot:content>
          <!-- ================================================ -->

          <section>

            <div v-if="$i18n.locale ==='fr_BE' || $i18n.locale ==='fr_LU'">
              Lorem ipsum en français.
            </div>
            <div v-else>
              Lorem ipsum in het nederlands.
            </div>

          </section>

          <!-- ================================================ -->
        </template>

        <template v-slot:nav >
          <section class="navigation" >
            <button @click.prevent="goto('registration');" v-if="registrationRequired">{{$t('navigation.signup')}}</button >
            <button @click.prevent="goto('form1');" v-else>{{$t('navigation.participate')}}</button>
          </section>

        </template>

      </page-form>








      <page-form :title="$t('pageTitles.registration')" v-else-if="currentPage==='registration'" key="registration">
        <template v-slot:content>
          <!-- ================================================ -->

          <breadcrumbs
              :steps="listOfCrumbsForThisAction"
              :currentStep="0"
              v-if="displayBreadCrumbs"
          ></breadcrumbs>

          <section>
            <div class="content center">
              Avant de vous inscrire, vous voudrez peut-être savoir <a href="#" @click.prevent="goto('instructions');">comment participer</a> ?
            </div>
          </section>

          <!--
                      <section id="registrationTextAbove">
                        <div class="content">
                          <p v-if="$i18n.locale ==='fr_BE' || $i18n.locale ==='fr_LU'">

                            Les conditions de participation à l’action CLIENT casback peuvent être consultées ici&nbsp;: <a :href="$t('footer.genterms')" @click.prevent="openPDFFile('conditions')" download>{{$t('footer.genterms')}}</a>. La participation implique l'acceptation de ces conditions et de toute mesure que nous devons prendre pour réaliser l’action.
                          </p>
                          <p v-else>
                            De deelnamevoorwaarden aan de CLIENT casback actie vind je hier: <a :href="$t('footer.genterms')" @click.prevent="openPDFFile('conditions')" download>{{$t('footer.genterms')}}</a>. Deelname impliceert de aanvaarding van deze algemene voorwaarden en van elke maatregel die we moeten nemen om de actie uit te voeren.
                          </p>

                        </div>
                      </section>
                    -->

          <section>
            <claim-blocs
                :bloc-type="['email', 'whatsapp', 'sms']"
                @goto="goto"
                @mean="setRegistrationMean"
                :lang="$i18n.locale.slice(0,2)"
                :idLead="lead.id"
                :whatsappcodes="currentCodes"
                @errorpresent="transmitErrorFromApi"
            >
              <template v-slot:title>
                <h2>{{$t('sectionTitles.wayOfRegistration')}}</h2>
              </template>
            </claim-blocs>
          </section>

          <section id="registrationTextBelow">
            <div class="content">
              {{$t('pageLitteracy.registration.byRegistering')}} <a v-if="$i18n.locale==='fr_BE' || $i18n.locale==='fr_LU'" href="#">{{$t('pageLitteracy.registration.privacyNotice')}}</a><a v-else href="#">{{$t('pageLitteracy.registration.privacyNotice')}}</a>
            </div>
          </section>

          <!-- ================================================ -->
        </template>
      </page-form>



















      <page-form :title="$t('pageTitles.confirmation')" v-else-if="currentPage==='confirmRegistration'" key="confirmRegistration">
        <template v-slot:content>
          <!-- ================================================ -->

          <breadcrumbs
              :steps="listOfCrumbsForThisAction"
              :currentStep="1"
              v-if="displayBreadCrumbs"
          ></breadcrumbs>

          <section>
            <h2>{{$t('sectionTitles.thanksForRegistration')}}</h2>

            <spacer :h="1"></spacer>

            <div id="confirmSMS" v-if="registrationMean=='phone'" class="centeredContent">
              <p>{{$t('pageLitteracy.confirmRegistration.smsLine1')}}</p>
              <p>{{$t('pageLitteracy.confirmRegistration.smsLine2')}}</p>
            </div>

            <div id="confirmWhatsapp" v-if="registrationMean==='whatsapp'" class="centeredContent">
              <p>{{$t('pageLitteracy.confirmRegistration.whatsappLine1')}}</p>
              <p>{{$t('pageLitteracy.confirmRegistration.whatsappLine2')}}</p>
            </div>

            <div id="confirmMAIL" v-if="registrationMean==='email'" class="centeredContent">
              <p>{{$t('pageLitteracy.confirmRegistration.mailLine1')}}</p>
              <p>{{$t('pageLitteracy.confirmRegistration.mailLine2')}}</p>
            </div>

          </section>

          <!-- ================================================ -->
        </template>

      </page-form>























      <page-form :title="$t('pageTitles.wrongCode')" v-else-if="currentPage==='wrongcode'" key="wrongcode">
        <template v-slot:content>
          <!-- ================================================ -->
          <section>
            <div class="simpletext">
              <p v-html="$t('pageLitteracy.wrongCode.text')"></p>
              <p v-if="registrationRequired">{{$t('pageLitteracy.wrongCode.textregistration')}}</p>
            </div>
          </section>
          <!-- ================================================ -->
        </template>
        <template v-slot:nav v-if="registrationRequired">
          <section class="navigation">
            <button @click.prevent="goto('registration');">{{$t('navigation.signup')}}</button>
          </section>
        </template>
      </page-form>





      <page-form :title="$t('pageTitles.wrongCode2')" v-else-if="currentPage==='wrongcode2'" key="wrongcode2">
        <template v-slot:content>
          <!-- ================================================ -->
          <section>
            <div class="simpletext">
              <p v-if="registrationRequired" v-html="$t('pageLitteracy.wrongCode.errorWithReparticipation')"></p>
              <p class="copy" v-html="getUrlParamsHTML()"></p>
            </div>
          </section>
          <!-- ================================================ -->
        </template>
      </page-form>


























      <page-form :title="$t('pageTitles.noform')" v-else-if="currentPage==='noform'" key="noform">
        <template v-slot:content>
          <!-- ================================================ -->
          <section>
            <div style="text-align:center;">
              <p>{{$t('pageLitteracy.noform.text')}}</p>
              <p>{{$t('pageLitteracy.noform.text2')}}</p>
            </div>
          </section>
          <!-- ================================================ -->
        </template>
      </page-form>






      <page-form :title="$t('pageTitles.maintenance')" v-else-if="currentPage==='maintenance'" key="maintenance">
        <template v-slot:content>
          <!-- ================================================ -->
          <section>
            <div style="text-align:center;">
              <p>{{$t('maintenance.line1')}}</p>
              <p>{{$t('maintenance.line2')}}</p>
              <p>{{$t('maintenance.thanks')}}</p>
            </div>
          </section>
          <!-- ================================================ -->
        </template>
      </page-form>









      <page-form :title="$t('printCoupons.page0.title')" v-else-if="currentPage==='choosecouponway' && allowFormToBeDisplayed" key="choosecouponway">
        <template v-slot:content>
          <section class="formgrid">
            <div class="col1"></div>
            <div class="col2">
              <p v-html="$t('printCoupons.page0.explanation')">
              </p>
            </div>
          </section>
        </template>
        <!-- Nav bar -->
        <template v-slot:nav>
          <section class="navigation">
            <button @click.prevent="userAskedToPrintCouponsNow">{{$t('printCoupons.page0.optionDirectPrint')}}</button>
            <button @click.prevent="userAskedToPrintCouponsLater">{{$t('printCoupons.page0.optionMailPrint')}}</button>
          </section>
        </template>
      </page-form>



      <page-form :title="$t('printCoupons.page1.title')" v-else-if="currentPage==='printcoupon' && allowFormToBeDisplayed" key="printcoupon">
        <template v-slot:content>
          <section class="formgrid">
            <div class="col1"></div>
            <div class="col2">

              <p class="softWarningText" v-html="$t('printCoupons.page1.attention')"></p>
              <ol>
                <li>{{$t('printCoupons.page1.step1')}}</li>
                <li>{{$t('printCoupons.page1.step2')}}</li>
                <li>{{$t('printCoupons.page1.step3')}}</li>
              </ol>

              <img src="@/assets/img/printer_ready.jpg" alt="" id="happyprinter">

              <!-- Custom Checkbox General Conditions -->
              <custom-checkbox
                  fieldname="conditionsCoupons"
                  inputid="demoConditionsCB"
                  helper=""
                  :isRequired="requiredFieldsShortList.includes('generalCondition')"
                  :idLead="lead.id"
                  v-model="fieldsValues.cg"
                  @runCheck="checkIfMustUnlockClaimCashbackButton"
                  special-style="vcheck"
                  class="col2"
              >
                <template v-slot:msg>
                  {{$t('printCoupons.page1.conditions')}}<br><a href="Terms and conditions" @click.prevent="openPDFFile('conditions')" download>{{$t('printCoupons.page1.moreInfo')}}.</a>
                </template>
              </custom-checkbox>
            </div>
          </section>
        </template>
        <!-- Nav bar -->
        <template v-slot:nav>
          <section class="navigation">
            <button @click.prevent="userAskedToPrintCoupons" :disabled="!isThisButtonActive('btn_cashback_on_printcoupon') || userAlreadyAskedToPrintHisCoupon">{{$t('printCoupons.page1.button')}}</button>
          </section>
        </template>
      </page-form>

      <page-form :title="$t('pageLitteracy.confirmationCoupon.title')" v-else-if="currentPage==='confirmationCoupon'" key="confirmationCoupon">
        <template v-slot:content>

          <!-- ================================================ -->
          <section>
            <div class="simpletext">
              <p v-html="$t('pageLitteracy.confirmationCoupon.textes.p1')"></p>
              <p>{{$t('pageLitteracy.confirmationCoupon.textes.p2')}}</p>

              <p v-if="!skipButtonIsClicked && surveyMustBeFilled && aCouponCouldBeSent" v-html="$t('pageLitteracy.confirmationCoupon.textes.coupon')"></p>

              <p>{{$t('pageLitteracy.confirmationCoupon.textes.p3')}}</p>
              <p>
                <em v-html="$t('pageLitteracy.confirmationCoupon.textes.p4')"></em>
              </p>
            </div>
          </section>
          <!-- ================================================ -->
        </template>
      </page-form>

      <page-form :title="$t('pageLitteracy.confirmationMail.title')" v-else-if="currentPage==='confirmationMail'" key="confirmationMail">
        <template v-slot:content>

          <!-- ================================================ -->
          <section>
            <div class="simpletext">
              <p v-html="$t('pageLitteracy.confirmationMail.textes.p1')"></p>
            </div>
          </section>
          <!-- ================================================ -->
        </template>
      </page-form>

















      <page-form :title="$t('pageTitles.form1')" v-else-if="currentPage==='form1' && allowFormToBeDisplayed" key="form1">
        <template v-slot:content>
          <!-- ================================================ -->
          <breadcrumbs
              :steps="listOfCrumbsForThisAction"
              :currentStep="0"
              v-if="displayBreadCrumbs"
          ></breadcrumbs>

          <section class="formgrid">

            <!-- Mandatory field line -->
            <p style="text-align: right; font-size:0.8em;" class="col4"><span style="color: red;" tabindex="-1">*</span> {{$t('inputLabel.mandatoryFields')}}</p>

            <img src="~@/assets/img/packshot.png" class="packshot col4">

            <spacer :h="1" class="col4"></spacer>

            <!-- ============================================ -->
            <h2>{{$t('sectionTitles.aboutYou')}}</h2>
            <!-- ============================================ -->


            <!-- TITLE -->
            <!--<select-api
            fieldname="idTitle"
            dataSource="titles"
            :placeholder="$t(`inputsTexts.title.${placeholderSize}`)"
            :label="$t('inputsTexts.title.label')"
            :helper="$t('inputsTexts.title.helper')"
            :isRequired="requiredFieldsShortList.includes('idTitle')"
            @runCheck="updateValidityOfThisField"
            @maintenanceDetected="activeMaintenancePage"
            v-model.lazy:modelValue="fieldsValues.idTitle"
            :caractersBeforeOpenList="1"
            :autocomplete="true"
            :isReparticipation="reparticipation"
            :isDisabled="false"
            class="col2"
            >
          </select-api>-->

            <!-- GENRE -->
            <select-api
                fieldname="gender"
                dataSource="genders"
                :placeholder="$t(`inputsTexts.gender.${placeholderSize}`)"
                :label="$t('inputsTexts.gender.label')"
                :helper="$t('inputsTexts.gender.helper')"
                :isRequired="requiredFieldsShortList.includes('idGender')"
                @runCheck="updateValidityOfThisField"
                @maintenanceDetected="activeMaintenancePage"
                v-model.lazy:modelValue='fieldsValues.idGender'
                :caractersBeforeOpenList="1"
                :autocomplete="true"
                :isReparticipation="reparticipation"
                :isDisabled="false"
                class="col2"
            >
            </select-api>


            <spacer :h="0" class="col4"></spacer>


            <!-- FIRSTNAME -->

            <custom-input
                fieldname="firstName"
                :placeholder="$t('inputsTexts.firstname.placeholder')"
                inputType="text"
                :idLead="lead.id"
                :label="$t('inputsTexts.firstname.label')"
                :isRequired="requiredFieldsShortList.includes('firstName')"
                :helper="$t('inputsTexts.firstname.helper')"
                v-model.lazy:modelValue="fieldsValues.firstname"
                @runCheck="checkIfMustUnlockClaimCashbackButton"
                @maintenanceDetected="activeMaintenancePage"
                class=""></custom-input>


            <!-- LASTNAME -->

            <custom-input
                fieldname="lastName"
                :placeholder="$t('inputsTexts.lastname.placeholder')"
                inputType="text"
                :idLead="lead.id"
                :label="$t('inputsTexts.lastname.label')"
                :isRequired="requiredFieldsShortList.includes('lastName')"
                :helper="$t('inputsTexts.lastname.helper')"
                v-model.lazy:modelValue="fieldsValues.lastname"
                @runCheck="checkIfMustUnlockClaimCashbackButton"
                @maintenanceDetected="activeMaintenancePage"
                class=""></custom-input>


            <!-- EMAIL -->

            <custom-input
                fieldname="email"
                :placeholder="$t('inputsTexts.email.placeholder')"
                inputType="text"
                :idLead="lead.id"
                :label="$t('inputsTexts.email.label')"
                :helper="$t('inputsTexts.email.helper')"
                :isRequired="requiredFieldsShortList.includes('email')"
                :disabled="isMailFieldDisabled"
                autocomplete="email"
                v-model.lazy:modelValue="fieldsValues.email"
                @runCheck="checkIfMustUnlockClaimCashbackButton"
                @maintenanceDetected="activeMaintenancePage"
            ></custom-input>



            <!-- PHONE -->
            <!--<phone-input
            fieldname="phoneNumber1"
            v-model="smsHelper"
            :countries="shortListOfAllowedCountries"
            inputType="phone"
            :idLead="lead.id"
            :placeholder="$t('inputsTexts.phone.placeholder')"
            :label="$t('inputsTexts.phone.label')"
            :helper="$t('inputsTexts.phone.helper')"
            :isRequired="requiredFieldsShortList.includes('phoneNumber1')"
            @internationalNumber="setPhone"
            @runCheck="checkIfMustUnlockClaimCashbackButton"
            @maintenanceDetected="activeMaintenancePage"
            :receivedNumber="fieldsValues.phone1"
            ref="phoneNumber1"></phone-input>
            -->


            <!-- IBAN -->

            <!--<custom-input
            fieldname="IBAN"
            :placeholder="$t('inputsTexts.iban.placeholder')"
            inputType="text"
            :idLead="lead.id"
            :label="$t('inputsTexts.iban.label')"
            :helper="$t('inputsTexts.iban.helper')"
            :isRequired="requiredFieldsShortList.includes('IBAN')"
            v-model.lazy:modelValue="fieldsValues.iban"
            @runCheck="checkIfMustUnlockClaimCashbackButton"
            @maintenanceDetected="activeMaintenancePage"
            ></custom-input>-->


            <!-- VAT CODE -->

            <!--<custom-input
            fieldname="VATNumber"
            :placeholder="$t('inputsTexts.VATNumber.placeholder')"
            inputType="text"
            :idLead="lead.id"
            :label="$t('inputsTexts.VATNumber.label')"
            :isRequired="requiredFieldsShortList.includes('VATNumber')"
            :helper="$t('inputsTexts.VATNumber.helper')"
            v-model.lazy:modelValue="fieldsValues.VATNumber"
            @runCheck="checkIfMustUnlockClaimCashbackButton"
            @maintenanceDetected="activeMaintenancePage"
            class="col2"></custom-input>-->



            <spacer :h="1" class="col4"></spacer>






            <!-- ============================================ -->
            <h2>{{$t('sectionTitles.address')}}</h2>
            <!-- ============================================ -->

            <!-- GOOGLE FIELD
            <div @if="autocompleteService && placeService" class="col4">
              <google-select
                  id="googlecomponent"
                  :label-name="$t('inputsTexts.googleAPI.label')"
                  @input="searchPlace(searchTerm)"
                  :modelValue="searchTerm"
                  @update:modelValue="newValue => searchTerm = newValue"
                  :autocompletes="selectList"
                  v-model:selected-value="selectedOption"
              ></google-select>
            </div>-->

            <!-- ADRESSE -->
            <custom-input
                fieldname="street1"
                :placeholder="$t('inputsTexts.street.placeholder')"
                inputType="text"
                :idLead="lead.id"
                :label="$t('inputsTexts.street.label')"
                :isRequired="requiredFieldsShortList.includes('street1')"
                :helper="$t('inputsTexts.street.helper')"
                v-model.lazy:modelValue="fieldsValues.street1"
                @runCheck="updateValidityOfThisField"
                @maintenanceDetected="activeMaintenancePage"
                :isDisabled="false"
                class=""></custom-input>

            <!-- NUMERO -->
            <custom-input
                fieldname="houseNumber"
                :placeholder="$t('inputsTexts.houseNumber.placeholder')"
                inputType="text"
                :idLead="lead.id"
                :label="$t('inputsTexts.houseNumber.label')"
                :isRequired="requiredFieldsShortList.includes('houseNumber')"
                :helper="$t('inputsTexts.houseNumber.helper')"
                v-model.lazy:modelValue="fieldsValues.housenumber"
                @runCheck="updateValidityOfThisField"
                @maintenanceDetected="activeMaintenancePage"
                :isDisabled="false"
                class="col1"></custom-input>

            <!-- BOITE -->
            <custom-input
                fieldname="boxNumber"
                :placeholder="$t('inputsTexts.boxNumber.placeholder')"
                inputType="text"
                :idLead="lead.id"
                :label="$t('inputsTexts.boxNumber.label')"
                :isRequired="requiredFieldsShortList.includes('boxNumber')"
                :helper="$t('inputsTexts.boxNumber.helper')"
                v-model.lazy:modelValue="fieldsValues.boxnumber"
                @runCheck="updateValidityOfThisField"
                @maintenanceDetected="activeMaintenancePage"
                class="col1"></custom-input>

            <!-- CODE POSTAL -->
            <custom-input
                fieldname="postalCode"
                :placeholder="$t('inputsTexts.postalCode.placeholder')"
                inputType="text"
                :idLead="lead.id"
                :label="$t('inputsTexts.postalCode.label')"
                :isRequired="requiredFieldsShortList.includes('postalCode')"
                :helper="$t('inputsTexts.postalCode.helper')"
                v-model.lazy:modelValue="fieldsValues.postalcode"
                @runCheck="updateValidityOfThisField"
                @maintenanceDetected="activeMaintenancePage"
                :isDisabled="false"
                class=""></custom-input>

            <!-- LOCALITE -->
            <custom-input
                fieldname="city"
                :placeholder="$t('inputsTexts.city.placeholder')"
                inputType="text"
                :idLead="lead.id"
                :label="$t('inputsTexts.city.label')"
                :isRequired="requiredFieldsShortList.includes('city')"
                :helper="$t('inputsTexts.city.helper')"
                v-model.lazy:modelValue="fieldsValues.city"
                @runCheck="updateValidityOfThisField"
                @maintenanceDetected="activeMaintenancePage"
                :isDisabled="false"
                class=""></custom-input>


            <select-api
                fieldname="countries"
                data-source="countries-allowed"
                identifier="id"
                description="description"
                :idLead="lead.id"
                :placeholder="$t('inputsTexts.country.placeholder')"
                :label="$t('inputsTexts.country.label')"
                :helper="$t('inputsTexts.country.helper')"
                :isRequired="requiredFieldsShortList.includes('idCountryCode')"
                :isReparticipation="reparticipation"
                v-model.lazy:modelValue="fieldsValues.idcountrycode"
                @runCheck="updateValidityOfThisField"
                @maintenanceDetected="activeMaintenancePage"
                :isDisabled="false"
                class="col2"
            ></select-api>


            <!--<free-input
                fieldname="countries"
                inputType="text"
                :idLead="lead.id"
                :acceptedValues="[]"
                placeholder=""
                :label="$t('inputsTexts.country.label')"
                :helper="$t('inputsTexts.country.helper')"
                :isRequired="requiredFieldsShortList.includes('idCountryCode')"
                v-model.lazy:modelValue="fieldsValues.descriptioncountrycode"
                @runCheck="updateValidityOfThisField"
                @maintenanceDetected="activeMaintenancePage"
                :isDisabled="true"
                class="col2"></free-input>-->



            <spacer :h="1" class="col4"></spacer>


            <!-- Custom Checkbox General Conditions -->
            <custom-checkbox
                fieldname="conditions"
                inputid="demoConditionsCB"
                helper=""
                :isRequired="requiredFieldsShortList.includes('generalCondition')"
                :idLead="lead.id"
                v-model="fieldsValues.cg"
                @runCheck="checkIfMustUnlockClaimCashbackButton"
                special-style="vcheck"
                class="col2"
            >
              <template v-slot:msg>
                {{$t('inputsTexts.conditions.part1')}} <a href="Terms and conditions" @click.prevent="openPDFFile('conditions')" download>{{$t('inputsTexts.conditions.link')}}</a>
              </template>
            </custom-checkbox>

            <!-- Custom Checkbox Optin -->
            <custom-checkbox
                v-if="!isPnGAction"
                fieldname="optin"
                inputid="optin"
                :helper="$t('inputsTexts.optin.helper')"
                :isRequired="false"
                v-model="fieldsValues.optin"
                @runCheck="checkIfMustUnlockClaimCashbackButton"
                special-style="vcheck"
                :idLead="lead.id"
                class="col2">
              <template v-slot:msg>{{$t('inputsTexts.optin.part1')}} <a :href="$t('inputsTexts.optin.link')" target="_blank" rel="noopener">{{$t('inputsTexts.optin.linklabel')}}</a> {{$t('inputsTexts.optin.part2')}}</template>
            </custom-checkbox>


            <div v-if="isPnGAction" class="col4">
              <custom-checkbox
                  fieldname="optin"
                  inputid="optin"
                  :helper="$t('inputsTexts.optin.helper')"
                  :isRequired="false"
                  v-model="fieldsValues.optin"
                  @runCheck="checkIfMustUnlockClaimCashbackButton"
                  special-style="vcheck"
                  :idLead="lead.id"
                  class="col2">
                <template v-slot:msg>{{$t('inputsTexts.optin2.part1')}} <a :href="$t('inputsTexts.optin2.link')" target="_blank" rel="noopener">{{$t('inputsTexts.optin2.linklabel')}}</a> {{$t('inputsTexts.optin2.part2')}} <a :href="$t('inputsTexts.optin2.link2')" target="_blank" rel="noopener">{{$t('inputsTexts.optin2.linklabel2')}}</a> {{$t('inputsTexts.optin2.part3')}}</template>
              </custom-checkbox>


              <div class="col4 legal">
                <h3>{{$t('PGLegal.title')}}</h3>
                <p> {{$t('PGLegal.mainText.personalData')}} <a @click="togglePopup('ads_info');" @keydown.prevent.enter="togglePopup('ads_info');" class="legalLink adsInfo" tabindex="0">{{$t('PGLegal.mainText.moreInfoLinkLabel')}}</a>. <br>
                  <br>
                  {{$t('PGLegal.mainText.privacyInfoText')}} <a @click="togglePopup('privacy_info');" @keydown.prevent.enter="togglePopup('privacy_info');" class="legalLink privacyInfo" tabindex="0">{{$t('PGLegal.mainText.privacyLinkLabel')}}</a> {{$t('PGLegal.mainText.privacyInfoText2')}} <a :href="$t('PGLegal.mainText.privacyLink')" target="_blank">{{$t('PGLegal.mainText.privacyPolicyLinkLabel')}}</a>{{$t('PGLegal.mainText.privacyInfoText3')}} </p>
              </div>

              <div id="overlay" v-show="popUpIsVisible"></div>

              <div id="popup" v-show="popUpIsVisible" role="dialog" ref="modal" tabindex="-1" @keyup.esc="closePopup">

                <popup-p-g :show-popup-text="currentPopUp" @popUpStatus="togglePopup"></popup-p-g>

              </div>

              <custom-checkbox
                  fieldname="optin2"
                  inputid="optin2"
                  :helper="$t('inputsTexts.optin.helper')"
                  :isRequired="true"
                  @runCheck="checkIfMustUnlockClaimCashbackButton"
                  v-model="fieldsValues.optin2"
                  special-style="vcheck"
                  :idLead="lead.id"
                  class="col4">
                <template v-slot:msg>{{$t('inputsTexts.optin3.part1')}} <a :href="$t('inputsTexts.optin3.link')" target="_blank" rel="noopener">{{$t('inputsTexts.optin3.linklabel')}}</a></template>
              </custom-checkbox>
            </div>

          </section>
          <!-- ================================================ -->
        </template>


        <!-- Nav bar -->
        <template v-slot:nav>
          <section class="navigation">
            <button @click.prevent="userWantToClaimHisCashback();" :disabled="!isThisButtonActive('btn_cashback_on_form1') || pendingParticipationSent">{{$t('navigation.claimCashback')}}</button>
          </section>

        </template>

      </page-form>















      <page-form :title="$t('pageTitles.form2')" v-else-if="currentPage==='form2'" key="form2">
        <template v-slot:content>
          <!-- ================================================ -->
          <breadcrumbs
              :steps="listOfCrumbsForThisAction"
              :currentStep="1"
              v-if="displayBreadCrumbs"
          ></breadcrumbs>

          <section class="formgrid">

            <!-- Mandatory field line -->
            <p style="text-align: right; font-size:0.8em;" class="col4"><span style="color: red;" tabindex="-1">*</span> {{$t('inputLabel.mandatoryFields')}}</p>


            <!-- ============================================ -->
            <h2>{{$t('sectionTitles.aboutProduct')}}</h2>
            <!-- ============================================ -->

            <!-- WHAT PRODUCT aka IDPOPITEM -->
            <popitem
                fieldname="selectProduct"
                :placeholder="$t('inputsTexts.product.placeholder')"
                :label="$t('inputsTexts.product.label')"
                data-from="popitems"
                identifier="id"
                description="description"
                :idLead="lead.id"
                :helper="$t('inputsTexts.product.helper')"
                :isRequired="requiredFieldsShortList.includes('idPOPItem')"
                :isReparticipation="reparticipation"
                v-model.lazy:modelValue='fieldsValues.popitems.popitem1'
                @runCheck="checkIfMustUnlockClaimCashbackButton"
                @maintenanceDetected="activeMaintenancePage"
                :autocomplete="true"
                class="col2"
            ></popitem>

            <!-- DATE OF PURCHASE -->
            <!--<custom-input
            fieldname="purchaseDate"
            inputType="date"
            data-date=""
            data-date-format="DD MMMM YYYY"
            :idLead="lead.id"
            :placeholder="$t('inputsTexts.datePurchase.placeholder')"
            :label="$t('inputsTexts.datePurchase.label')"
            :helper="$t('inputsTexts.datePurchase.helper')"
            :isRequired="requiredFieldsShortList.includes('purchaseDate')"
            v-model.lazy:modelValue="fieldsValues.purchaseDate"
            @runCheck="checkIfMustUnlockClaimCashbackButton"
            @maintenanceDetected="activeMaintenancePage"
            ></custom-input>-->

            <custom-date-picker
                fieldname="purchaseDate"
                inputType="text"
                data-date=""
                data-date-format="DD MMMM YYYY"
                :idLead="lead.id"
                :placeholder="$t('inputsTexts.datePurchase.placeholder')"
                :label="$t('inputsTexts.datePurchase.label')"
                :helper="$t('inputsTexts.datePurchase.helper')"
                :isRequired="requiredFieldsShortList.includes('purchaseDate')"
                v-model.lazy:modelValue="fieldsValues.purchaseDate"
                @runCheck="checkIfMustUnlockClaimCashbackButton"
                @maintenanceDetected="activeMaintenancePage"
            ></custom-date-picker>

            <!-- RETAILER IN A LIST -->
            <select-api
                fieldname="idPOPRetailer"
                dataSource="retailers"
                :placeholder="$t('inputsTexts.retailer.placeholder')"
                :label="$t('inputsTexts.retailer.label')"
                :helper="$t('inputsTexts.retailer.helper')"
                :isRequired="requiredFieldsShortList.includes('idPOPRetailer')"
                @runCheck="updateValidityOfThisField"
                @maintenanceDetected="activeMaintenancePage"
                v-model.lazy:modelValue="fieldsValues.retailer"
                :caractersBeforeOpenList="1"
                :autocomplete="true"
                :isReparticipation="reparticipation"
                :isDisabled="false"
                class="col2"
            >
            </select-api>

            <!-- FREE RETAILER NAME -->
            <custom-input
                v-show="unlockFreeRetailersIDs.includes(fieldsValues.retailer)"
                fieldname="freeRetailerName"
                inputType="text"
                :idLead="lead.id"
                :placeholder="$t('inputsTexts.freeRetailer.placeholder')"
                :label="$t('inputsTexts.freeRetailer.label')"
                :helper="$t('inputsTexts.freeRetailer.helper')"
                :isRequired="unlockFreeRetailersIDs.includes(fieldsValues.retailer)"
                v-model.lazy:modelValue="fieldsValues.freeRetailer"
                @runCheck="checkIfMustUnlockClaimCashbackButton"
                @maintenanceDetected="activeMaintenancePage"
            ></custom-input>


            <!-- FREE FIELD THAT ACCEPTS ONLY CERTAIN VALUES -->
            <!--
            <free-input
            fieldname="idLeadTMD1"
            inputType="text"
            :idLead="lead.id"
            :acceptedValues="['3060-035', '1234-056']"
            :placeholder="$t('inputsTexts.cnk1.placeholder')"
            :label="$t('inputsTexts.cnk1.label')"
            :helper="$t('inputsTexts.cnk1.helper')"
            :isRequired="true"
            v-model.lazy:modelValue="fieldsValues.cnk1"
            @runCheck="checkIfMustUnlockClaimCashbackButton"
            ></free-input>
          -->

            <!-- Spacer required to force a return to the next line -->
            <spacer :h="0" class="col4"></spacer>

            <!-- SERIAL NUMBER -->
            <!--
            <custom-input
            fieldname="serialNumber"
            inputType="text"
            :idLead="lead.id"
            :placeholder="$t('inputsTexts.serialNumber.placeholder')"
            :label="$t('inputsTexts.serialNumber.label')"
            :helper="$t('inputsTexts.serialNumber.helper')"
            :isRequired="requiredFieldsShortList.includes('serialNumber')"
            v-model.lazy:modelValue="fieldsValues.serialNumber"
            @runCheck="checkIfMustUnlockClaimCashbackButton"
            @maintenanceDetected="activeMaintenancePage"
            ></custom-input>
          -->

            <!-- QUANTITY -->
            <custom-input
                fieldname="quantity"
                inputType="text"
                :idLead="lead.id"
                :placeholder="$t('inputsTexts.quantity.placeholder')"
                :label="$t('inputsTexts.quantity.label')"
                :helper="$t('inputsTexts.quantity.helper')"
                :isRequired="requiredFieldsShortList.includes('quantity')"
                v-model.lazy:modelValue="fieldsValues.quantity"
                @runCheck="checkIfMustUnlockClaimCashbackButton"
                @maintenanceDetected="activeMaintenancePage"
            ></custom-input>


            <!-- ============================================ -->
            <h2>{{$t('sectionTitles.ticketUpload')}}</h2>
            <!-- ============================================ -->


            <!-- Proof of purchase -->
            <upload
                :label="$t('inputsTexts.uploads.ticket.label')"
                fieldname="file"
                post-upload-url-prop="/participations/upload"
                :idLead="lead.id"
                typeOfUpload="ticket"
                :isRequired="true"
                :idParticipationOwner="ownerData.id"
                @runCheck="checkIfMustUnlockClaimCashbackButton"
                @maintenanceDetected="activeMaintenancePage"
                class="col4"
            ></upload>

            <!-- More Proof of purchase (dropzone1 up to dropzone6) -->
            <!--
            <upload
            :label="$t('inputsTexts.uploads.dropzone1.label')"
            fieldname="fileBarCode"
            post-upload-url-prop="/participations/upload"
            :idLead="lead.id"
            typeOfUpload="dropzone1"
            :isRequired="true"
            :idParticipationOwner="ownerData.id"
            @runCheck="checkIfMustUnlockClaimCashbackButton"
            ></upload>
          -->
            <spacer :h="1" class="col4"></spacer>


            <!-- Custom Checkbox General Conditions -->
            <custom-checkbox
                fieldname="conditions"
                inputid="demoConditionsCB"
                helper=""
                :isRequired="requiredFieldsShortList.includes('generalCondition')"
                :idLead="lead.id"
                v-model="fieldsValues.cg"
                @runCheck="checkIfMustUnlockClaimCashbackButton"
                special-style="vcheck"
                class="col2"
            >
              <template v-slot:msg>
                {{$t('inputsTexts.conditions.part1')}} <a href="Terms and conditions" @click.prevent="openPDFFile('conditions')" download>{{$t('inputsTexts.conditions.link')}}</a>
              </template>
            </custom-checkbox>

            <!-- Custom Checkbox Optin -->
            <custom-checkbox
                v-if="!isPnGAction"
                fieldname="optin"
                inputid="optin"
                :helper="$t('inputsTexts.optin.helper')"
                :isRequired="false"
                v-model="fieldsValues.optin"
                @runCheck="checkIfMustUnlockClaimCashbackButton"
                special-style="vcheck"
                :idLead="lead.id"
                class="col2">
              <template v-slot:msg>{{$t('inputsTexts.optin.part1')}} <a :href="$t('inputsTexts.optin.link')" target="_blank" rel="noopener">{{$t('inputsTexts.optin.linklabel')}}</a> {{$t('inputsTexts.optin.part2')}}</template>
            </custom-checkbox>


            <div v-if="isPnGAction" class="col4">
              <custom-checkbox
                  fieldname="optin"
                  inputid="optin"
                  :helper="$t('inputsTexts.optin.helper')"
                  :isRequired="false"
                  v-model="fieldsValues.optin"
                  @runCheck="checkIfMustUnlockClaimCashbackButton"
                  special-style="vcheck"
                  :idLead="lead.id"
                  class="col2">
                <template v-slot:msg>{{$t('inputsTexts.optin2.part1')}} <a :href="$t('inputsTexts.optin2.link')" target="_blank" rel="noopener">{{$t('inputsTexts.optin2.linklabel')}}</a> {{$t('inputsTexts.optin2.part2')}} <a :href="$t('inputsTexts.optin2.link2')" target="_blank" rel="noopener">{{$t('inputsTexts.optin2.linklabel2')}}</a> {{$t('inputsTexts.optin2.part3')}}</template>
              </custom-checkbox>


              <div class="col4 legal">
                <h3>{{$t('PGLegal.title')}}</h3>
                <p> {{$t('PGLegal.mainText.personalData')}} <a @click="togglePopup('ads_info');" @keydown.prevent.enter="togglePopup('ads_info');" class="legalLink adsInfo" tabindex="0">{{$t('PGLegal.mainText.moreInfoLinkLabel')}}</a>. <br>
                  <br>
                  {{$t('PGLegal.mainText.privacyInfoText')}} <a @click="togglePopup('privacy_info');" @keydown.prevent.enter="togglePopup('privacy_info');" class="legalLink privacyInfo" tabindex="0">{{$t('PGLegal.mainText.privacyLinkLabel')}}</a> {{$t('PGLegal.mainText.privacyInfoText2')}} <a :href="$t('PGLegal.mainText.privacyLink')" target="_blank">{{$t('PGLegal.mainText.privacyPolicyLinkLabel')}}</a>{{$t('PGLegal.mainText.privacyInfoText3')}} </p>
              </div>

              <div id="overlay" v-show="popUpIsVisible"></div>

              <div id="popup" v-show="popUpIsVisible" role="dialog" ref="modal" tabindex="-1" @keyup.esc="closePopup">

                <popup-p-g :show-popup-text="currentPopUp" @popUpStatus="togglePopup"></popup-p-g>

              </div>

              <custom-checkbox
                  fieldname="optin2"
                  inputid="optin2"
                  :helper="$t('inputsTexts.optin.helper')"
                  :isRequired="true"
                  @runCheck="checkIfMustUnlockClaimCashbackButton"
                  v-model="fieldsValues.optin2"
                  special-style="vcheck"
                  :idLead="lead.id"
                  class="col4">
                <template v-slot:msg>{{$t('inputsTexts.optin3.part1')}} <a :href="$t('inputsTexts.optin3.link')" target="_blank" rel="noopener">{{$t('inputsTexts.optin3.linklabel')}}</a></template>
              </custom-checkbox>


            </div>


            <div class="col4">{{$t('pageLitteracy.form1.legal')}}</div>

          </section>
          <!-- ================================================ -->
        </template>

        <!-- Nav bar -->
        <template v-slot:nav>
          <section class="navigation">
            <button @click.prevent="userWantToClaimHisCashback()" :disabled="!isThisButtonActive('btn_cashback_on_form2') || pendingParticipationSent">{{$t('navigation.claimCashback')}}</button>
          </section>

        </template>

      </page-form>
















      <page-form :title="$t('pageTitles.catalog')" v-else-if="currentPage==='catalog'" key="catalog">
        <template v-slot:content>
          <!-- ============================================ -->

          <section class="formgrid">

            <h2 class="col4">Les champs qui fonctionnent</h2>

            <h3>Input with regex</h3>

            <!-- This input use a regex as validator. -->
            <!-- Here, the regex check for the reference the user have for his file: 12345_123456… -->
            <!-- ! the componant will change - to _ --><!-- Don't forget to modify if needed (see replaceSomeCaracters function) -->
            <!-- ! fieldsValues.reference is not a standard data send with the participation -->
            <!-- …but can be used to get an Answer to a META -->
            <input-regex
                fieldname="Reference"
                inputType="text"
                :acceptedValues="/[0-9]{5}[_-][0-9]{6,12}/"
                :label="$t('inputsTexts.refInput.label')"
                :placeholder="$t('inputsTexts.refInput.placeholder')"
                :helper="$t('inputsTexts.refInput.helper')"
                :isRequired="true"
                v-model.lazy:modelValue="fieldsValues.reference"
                @runCheck="checkIfMustUnlockClaimCashbackButton"
            ></input-regex>



            <h3>Text Area</h3>

            <!-- ! fieldsValues.freeAnswer is not a standard data send with the participation -->
            <!-- …but can be used to get a freeAnswer for a META -->
            <text-area
                fieldname="freeAnswer"
                :label="$t('inputsTexts.textArea.label')"
                :placeholder="$t('inputsTexts.textArea.placeholder')"
                :helper="$t('inputsTexts.textArea.helper')"
                :isRequired="true"
                v-model.lazy:modelValue="fieldsValues.freeAnswer"
                @runCheck="checkIfMustUnlockClaimCashbackButton"
                class="col2"
            ></text-area>


            <h3>Sliders</h3>

            <!-- WHAT PRODUCT aka IDPOPITEM -->
            <popitem
                fieldname="selectProduct"
                :placeholder="$t('inputsTexts.product.placeholder')"
                label="POPITEM"
                data-from="popitems"
                identifier="id"
                description="description"
                :idLead="lead.id"
                :helper="$t('inputsTexts.product.helper')"
                :isRequired="requiredFieldsShortList.includes('idPOPItem')"
                :isReparticipation="reparticipation"
                v-model.lazy:modelValue='fieldsValues.popitems.popitem1'
                @runCheck="checkIfMustUnlockClaimCashbackButton"
                @maintenanceDetected="activeMaintenancePage"
                class="col2"
            ></popitem>

            <!-- NEW SELECT CUSTOM LIST -->
            <!-- Reçoit une liste "manuelle" de la part du form -->
            <select-free
                fieldname="SelectFree"
                :dataArray="[
				{id:0, description:'Choix 1'},
				{id:1, description:'Choix 2'},
				{id:2, description:'Choix 3'},
				{id:3, description:'Choix 1, 2 et 3'},
				{id:4, description:'Lorem ipsum'}
				]"
                placeholder=""
                label="FREE SELECT"
                helper="Vous pouvez utiliser les flèches pour vous déplacer."
                :isRequired="true"
                @runCheck="updateValidityOfThisField"
                @maintenanceDetected="activeMaintenancePage"
                v-model.lazy:modelValue="fieldsValues.dataDestination.free"
                :caractersBeforeOpenList="3"
                :autocomplete="true"
                :isDisabled="false"
                class="col2"
            >
            </select-free>

            <!-- New SELECT API -->
            <!-- Reçoit un String pour une requête API -->
            <select-api
                fieldname="idTitle"
                dataSource="titles"
                :placeholder="$t('inputsTexts.title.placeholder')"
                :label="$t('inputsTexts.title.label')"
                :helper="$t('inputsTexts.title.helper')"
                :isRequired="requiredFieldsShortList.includes('idTitle')"
                @runCheck="updateValidityOfThisField"
                @maintenanceDetected="activeMaintenancePage"
                v-model.lazy:modelValue="fieldsValues.idTitle"
                :caractersBeforeOpenList="1"
                :autocomplete="true"
                :isReparticipation="reparticipation"
                :isDisabled="false"
                class="col2"
            ></select-api>


            <select-api
                fieldname="idPOPRetailer"
                dataSource="retailers"
                :placeholder="$t('inputsTexts.retailer.placeholder')"
                :label="$t('inputsTexts.retailer.label')"
                :helper="$t('inputsTexts.retailer.helper')"
                :isRequired="requiredFieldsShortList.includes('idPOPRetailer')"
                @runCheck="updateValidityOfThisField"
                @maintenanceDetected="activeMaintenancePage"
                v-model.lazy:modelValue="fieldsValues.retailer"
                :caractersBeforeOpenList="1"
                :autocomplete="true"
                :isReparticipation="reparticipation"
                :isDisabled="false"
                class="col2"
            ></select-api>





            <!-- SLIDERS -->

            <!-- Type 1: numbers, no fantasy -->
            <free-slider
                fieldname="answerTheQuestion1"
                label="lorem ipsum dolor sit amet 1"
                helper="Test lorem ipsum dolor sit amet"
                :steps="50"
                :defaultValue="25"
                :type="1"
                v-model.lazy:modelValue="fieldsValues.slider1"
                :isRequired="false"
                class="col2"
            ></free-slider>

            <!-- Type 2: smiley as gradation -->
            <!-- Note: counter goes from 0 to N, so there is n+1 positions. -->
            <!-- If, like here, I want 3 position, I must set 2 steps. -->
            <free-slider
                fieldname="answerTheQuestion2"
                label="lorem ipsum dolor sit amet 2"
                :steps="2"
                :defaultValue="1"
                :type="2"
                v-model.lazy:modelValue="fieldsValues.slider2"
                :isRequired="false"
                class="col2"
            ></free-slider>

            <!-- Type 3: numbers, smileys as cursor -->
            <free-slider
                fieldname="answerTheQuestion3"
                label="lorem ipsum dolor sit amet 3"
                :steps="10"
                :defaultValue="5"
                :type="3"
                v-model.lazy:modelValue="fieldsValues.slider3"
                :isRequired="false"
                class="col2"
            ></free-slider>

            <!-- Type 4: red-green colored cursor -->
            <free-slider
                fieldname="answerTheQuestion4"
                label="lorem ipsum dolor sit amet 4"
                :steps="10"
                :defaultValue="5"
                :type="4"
                v-model.lazy:modelValue="fieldsValues.slider4"
                :isRequired="false"
                class="col2"
            ></free-slider>

            <!-- Type 5: red-green colored track, neutral cursor -->
            <free-slider
                fieldname="answerTheQuestion5"
                label="lorem ipsum dolor sit amet 5"
                :steps="10"
                :defaultValue="5"
                :type="5"
                v-model.lazy:modelValue="fieldsValues.slider5"
                :isRequired="false"
                class="col2"
            ></free-slider>

            <!-- Type 6: colored track following cursor position, neutral cursor -->
            <free-slider
                fieldname="answerTheQuestion6"
                label="lorem ipsum dolor sit amet 6"
                :steps="10"
                :defaultValue="5"
                :type="6"
                v-model.lazy:modelValue="fieldsValues.slider6"
                :isRequired="false"
                class="col2"
            ></free-slider>

            <!-- META slider, takes an id as label -->
            <!-- Types are the same as free-sliders -->
            <meta-slider
                fieldname="answerTheQuestion6"
                :label="metaIds[1]"
                :steps="10"
                :defaultValue="5"
                :type="3"
                v-model.lazy:modelValue="fieldsValues.slider7"
                :isRequired="false"
                class="col4"
            ></meta-slider>

            <meta-slider
                fieldname="answerTheQuestion6"
                :label="metaIds[1]"
                :steps="10"
                :defaultValue="5"
                :type="3"
                v-model.lazy:modelValue="fieldsValues.slider7"
                :isRequired="false"
                class="col1"
            ></meta-slider>





            <!-- NEW SELECT META -->
            <!-- Recoit un ID de meta -->
            <select-meta
                fieldname="SelectMetaDemo"
                data-type="meta"
                :dataSource="metaIds[2]"
                placeholder=""
                helper="Receive a meta ID"
                :isRequired="false"
                @runCheck="checkIfMustUnlockClaimCashbackButton"
                @maintenanceDetected="activeMaintenancePage"
                v-model.lazy:modelValue="fieldsValues.dataDestination.meta"
                :caractersBeforeOpenList="1"
                :autocomplete="true"
                :isReparticipation="reparticipation"
                class="col2"
            ></select-meta>


          </section>

          <hr>

          <section class="formgrid">
            <h2 class="col4">Les autres champs, expérimentaux ou obsoletes</h2>

            <!-- Ajouter ici EAN et ListEAN -->

            <!-- Champ "EAN-INPUT" pour que le conso TAPE un EAN valide -->
            <!-- Communique un EAN -->
            <ean-input
                fieldname="gtin"
                dataName="gtin"
                translationSource="gtin"
                inputType="number"
                :isRequired="true"
                v-model.lazy:modelValue="fieldsValues.gtin"
                @runCheck="checkIfMustUnlockClaimCashbackButton"
                :isDisabled="false"
            ></ean-input>

            <!-- Liste d'EAN (fournie par Action-API au chargement du form) -->
            <!-- Enregistre un ID -->
            <select-gtin
                fieldname="gtin"
                :dataArray="synonyms.filter(snn => parseInt(snn.idpopitem) === parseInt(fieldsValues.popitems.popitem1))"
                translationSource="listEan"
                :isRequired="true"
                @runCheck="checkIfMustUnlockClaimCashbackButton"
                v-model.lazy:modelValue="fieldsValues.gtinId"
                :caractersBeforeOpenList="3"
                :autocomplete="true"
                :isDisabled="false"
                class="col2"
            ></select-gtin>





            <!-- * Obsolete
                <meta-custom-select
                fieldname="idLeadTMD4"
                label="META-CUSTOM-SELECT"
                :placeholder="$t('inputsTexts.dropdown.placeholder')"
                :data-from="metaIds[3]"
                :idLead="lead.id"
                helper=""
                :isRequired="true"
                :isReparticipation="reparticipation"
                v-model.lazy:modelValue="fieldsValues.survey.answersSQ4"
                @runCheck="checkIfMustUnlockClaimCashbackButton"
                @maintenanceDetected="activeMaintenancePage"
                class="col2"></meta-custom-select>
              -->



            <!-- A line with a counter. CounterType can be "participationsLeft" or "daysLeft" -->
            <div class="col4">
              <!-- Warning: the condition is reversed here, to be visible in the catalog if it is hidden on the app -->
              <participation-counter
                  v-show="!counterIsVisible"
                  counterType="participationsLeft"
                  :displayedNumber="parseInt(lead.participations_left)"
              ></participation-counter>
            </div>



          </section>

          <!-- ================================================ -->
        </template>

        <!-- Nav bar -->
        <template v-slot:nav>
          <section class="navigation">
            <button @click.prevent="userWantToClaimHisCashback()" :disabled="!isThisButtonActive('btn_cashback_on_form2') || pendingParticipationSent">{{$t('navigation.claimCashback')}}</button>
          </section>
        </template>

      </page-form>














      <page-form :title="$t('pageTitles.choiceSurvey')" v-else-if="currentPage==='choiceSurvey'" key="choiceSurvey">
        <template v-slot:content>
          <!-- ================================================ -->
          <breadcrumbs
              :steps="listOfCrumbsForThisAction"
              :currentStep="3"
              v-if="displayBreadCrumbs"
          ></breadcrumbs>
          <!-- ================================================ -->
          <section>
            <h2>{{$t('pageLitteracy.choiceSurvey.fillSurveyIncite')}}</h2>
            <!-- add text here if needed -->
          </section>
          <!-- ================================================ -->
        </template>
        <template v-slot:nav>
          <!-- ================================================ -->
          <section class="navigation">
            <button @click.prevent="goto('survey')" :disabled="forceInactiveButtons">{{$t('navigation.doSurvey')}}</button>
            <button @click.prevent="skipSurvey()" :disabled="forceInactiveButtons">{{$t('navigation.skipSurvey')}}</button>
            <button @click.prevent="goto('form1')">{{$t('navigation.previous')}}</button>

          </section>

          <!-- ================================================ -->
        </template>
      </page-form>


















      <page-form :title="$t('pageTitles.survey')" v-else-if="currentPage==='survey'" key="survey">
        <template v-slot:content>

          <breadcrumbs
              :steps="listOfCrumbsForThisAction"
              :currentStep="3"
              v-if="displayBreadCrumbs"
          ></breadcrumbs>
          <!-- ================================================ -->
          <section class="formgrid">

            <h2>{{$t('pageLitteracy.survey.title')}}</h2>

            <!-- ================== SURVEY ================== -->


            <!-- META : NUMBER -->
            <meta-input
                fieldname="idLeadTMD1"
                placeholder=""
                inputType="number"
                :idLead="lead.id"
                :label="metaIds[0]"
                :data-from="metaIds[0]"
                :isRequired="true"
                helper=""
                v-model.lazy:modelValue="fieldsValues.survey.answersSQ1alt"
                @runCheck="checkIfMustUnlockClaimCashbackButton"
                @maintenanceDetected="activeMaintenancePage"
                class="col2"></meta-input>

            <!-- META : FREE TEXT -->
            <meta-input
                fieldname="idLeadTMD2"
                placeholder=""
                inputType="text"
                :idLead="lead.id"
                :label="metaIds[1]"
                :data-from="metaIds[1]"
                :isRequired="true"
                helper=""
                v-model.lazy:modelValue="fieldsValues.survey.answersSQ2alt"
                @runCheck="checkIfMustUnlockClaimCashbackButton"
                @maintenanceDetected="activeMaintenancePage"
                class="col2"></meta-input>

            <!-- SELECT META -->
            <select-meta
                fieldname="idLeadTMD3"
                data-type="meta"
                :dataSource="metaIds[2]"
                :placeholder="$t('inputsTexts.dropdown.placeholder')"
                helper="Receive a meta ID"
                :isRequired="true"
                @runCheck="checkIfMustUnlockClaimCashbackButton"
                @maintenanceDetected="activeMaintenancePage"
                v-model.lazy:modelValue="fieldsValues.survey.answersSQ3"
                :caractersBeforeOpenList="1"
                :autocomplete="true"
                :isReparticipation="reparticipation"
                class="col2"
            >
            </select-meta>



            <!-- SELECT + FREE ANSWER -->
            <select-meta
                fieldname="idLeadTMD4"
                data-type="meta"
                :dataSource="metaIds[3]"
                :placeholder="$t('inputsTexts.dropdown.placeholder')"
                helper="Receive a meta ID"
                :isRequired="true"
                @runCheck="checkIfMustUnlockClaimCashbackButton"
                @maintenanceDetected="activeMaintenancePage"
                v-model.lazy:modelValue="fieldsValues.survey.answersSQ4"
                :caractersBeforeOpenList="1"
                :autocomplete="true"
                :isReparticipation="reparticipation"
                class="col2"
            >
            </select-meta>


            <free-input
                v-show="metaOtherFreeFieldsIds.includes(fieldsValues.survey.answersSQ4)"
                fieldname="idLeadTMD4"
                inputType="text"
                :idLead="lead.id"
                :acceptedValues="[]"
                placeholder="Placeholder non dynamique"
                label="Label non dynamique"
                helper="Helper non dynamique"
                :isRequired="metaOtherFreeFieldsIds.includes(fieldsValues.survey.answersSQ4)"
                v-model.lazy:modelValue="fieldsValues.survey.answersSQ4alt"
                @runCheck="checkIfMustUnlockClaimCashbackButton"></free-input>

            <!-- ! The IDs should be dynamic here -->
            <!-- META : DATE -->
            <meta-input
                fieldname="idLeadTMD5"
                placeholder=""
                inputType="date"
                :idLead="lead.id"
                :label="metaIds[4]"
                :data-from="metaIds[4]"
                :isRequired="true"
                helper=""
                v-model.lazy:modelValue="fieldsValues.survey.answersSQ5alt"
                @runCheck="checkIfMustUnlockClaimCashbackButton"
                @maintenanceDetected="activeMaintenancePage"
                class="col2"></meta-input>


          </section>

          <!-- ================================================ -->

        </template>

        <template v-slot:nav>
          <section class="navigation">
            <button @click="checkListBeforeSending()" :disabled="!isThisButtonActive('btn_cashback_on_survey') || forceInactiveButtons">{{$t('navigation.claimCashback')}}</button>
            <!-- <button @click="sendParticipation" :disabled="!isThisButtonActive('btn_cashback_on_survey') || forceInactiveButtons">{{$t('navigation.claimCashback')}}</button> -->

          </section>

        </template>

      </page-form>


















      <page-form :title="$t('pageTitles.summary')" v-else-if="currentPage==='summary'" key="summary">
        <template v-slot:content>
          <!-- ================================================ -->
          <section>
            <div class="simpletext">
              <p>Cette page affiche un résumé des valeurs des champs communiquées pour confirmation de l'utilisateur</p>
              <p>Utilisée dans le cas "Delhaize", par exemple. Stockée pour futur référence.</p>
            </div>
          </section>
          <!-- ================================================ -->
        </template>
      </page-form>




















      <page-form :title="$t('pageTitles.alreadySend')" v-else-if="currentPage==='participated'" key="participated">
        <template v-slot:content>
          <!-- ================================================ -->
          <section>
            <div class="simpletext">
              <p>{{$t('pageLitteracy.alreadySend')}}</p>
            </div>
          </section>
          <!-- ================================================ -->
        </template>
      </page-form>


























      <page-form :title="$t('pageLitteracy.confirmation.title')" v-else-if="currentPage==='confirmation'" key="confirmation">
        <template v-slot:content>

          <breadcrumbs
              :steps="listOfCrumbsForThisAction"
              :currentStep="4"
              v-if="displayBreadCrumbs"
          ></breadcrumbs>
          <!-- ================================================ -->
          <section>
            <div class="simpletext">
              <p v-html="$t('pageLitteracy.confirmation.textes.p1')"></p>
              <p>{{$t('pageLitteracy.confirmation.textes.p2')}}</p>

              <p v-if="!skipButtonIsClicked && surveyMustBeFilled && aCouponCouldBeSent" v-html="$t('pageLitteracy.confirmation.textes.coupon')"></p>

              <p>{{$t('pageLitteracy.confirmation.textes.p3')}}</p>
              <p>
                <em v-html="$t('pageLitteracy.confirmation.textes.p4')"></em>
              </p>
            </div>

          </section>
          <!-- ================================================ -->
        </template>
      </page-form>












    </transition>



    <full-test-panel
        v-if="config.isFullTestPanelAllowed && fullTestPanelIsRequested"
        :class="{'retracted':fullTestPanelHidden}"
        @clientWantToGoThere="goto"
        @swap="toggleTestPanelVisibility"
    >
    </full-test-panel>



    <div id="cheatnav" class="debug" v-if="isDevNavVisible && isAdminNavAllowed && allowFormToBeDisplayed" :class="{'retracted':cheatPanelHidden}">
      <div class="swiper" @click="toggleCheatPanel">
        <span v-if="cheatPanelHidden">&lt;</span>
        <span v-if="!cheatPanelHidden">&gt;</span>
      </div>

      <div class="innercheat">

        <h3 style="padding:0.25em 0;">Hdref: {{lead.referencehd}} — Id: {{lead.id}}</h3>

        <fieldset>
          <legend>Infos</legend>
          Acceptance start: <strong>{{getSimpleDate(actionFullDates?.registration?.start)}}</strong> | end: <strong>{{getSimpleDate(actionFullDates?.participation?.end)}}</strong><br>
          Display start date (+6 week): <strong>{{getSimpleDate(addSixWeeksTo(actionFullDates?.registration?.start))}}</strong>
          <hr>
          Registration Required: <strong>{{registrationRequired}}</strong><br>
          Survey possible: <strong>{{thereIsASurveyInThisAction}}</strong><br>
          Participations: <strong>{{lead.participations_total}}/{{lead.maxparticipationcounter}} - {{lead.participations_left}} left</strong><br>
          <hr>
          Current page technical name: <strong>{{currentPage}}</strong><br>
          Current locale: <strong>{{$i18n.locale}}</strong><br>
          Form display allowed: <strong>{{allowFormToBeDisplayed}}</strong><br>

        </fieldset>



        <fieldset>
          <legend>Navigation: "the user's form"</legend>
          <ul>
            <li @click="goto('form1')" :class="{active: currentPage == 'form1'}">Form Page 1</li>
            <li @click="goto('form2')" :class="{active: currentPage == 'form2'}">Form Page 2</li>
            <li @click="goto('confirmation')" :class="{active: currentPage == 'confirmation'}">Participation confirm.</li>
          </ul>
        </fieldset>

        <fieldset>
          <legend>Optionnal or test pages</legend>
          <ul>
            <li @click="goto('registration')" :class="{active: currentPage == 'registration'}">Registration</li>
            <li @click="goto('confirmRegistration')" :class="{active: currentPage == 'confirmRegistration'}">Registration confirm.</li>
            <li @click="goto('choiceSurvey')" :class="{active: currentPage == 'choiceSurvey'}">Choice survey</li>
            <li @click="goto('survey')" :class="{active: currentPage == 'survey'}">Survey</li>
            <li @click="goto('landing')" :class="{active: currentPage == 'landing'}">Landing</li>
            <li @click="goto('instructions')" :class="{active: currentPage == 'instructions'}">Instructions</li>
            <li @click="goto('summary')" :class="{active: currentPage == 'summary'}">Summary</li>
            <li @click="goto('catalog')" :class="{active: currentPage == 'catalog'}">Catalogue</li>
          </ul>
        </fieldset>

        <fieldset>
          <legend>Fixed pages in all forms</legend>
          <ul>
            <li @click="goto('beforeStart')" :class="{active: currentPage == 'beforeStart'}">Before Action</li>
            <li @click="goto('actionEnded')" :class="{active: currentPage == 'actionEnded'}">After Action</li>
            <li @click="goto('maxReached')" :class="{active: currentPage == 'maxReached'}">Max participation</li>
            <li @click="goto('wrongcode')" :class="{active: currentPage == 'wrongcode'}">Wrong code</li>
            <li @click="goto('wrongcode2')" :class="{active: currentPage == 'wrongcode2'}">Wrong code 2</li>
            <li @click="goto('participated')" :class="{active: currentPage == 'participated'}">Participation already sent</li>
            <li @click="goto('maintenance')" :class="{active: currentPage == 'maintenance'}">Maintenance</li>
            <li @click="goto('loading')" :class="{active: currentPage == 'loading'}">Loading</li>
            <li @click="goto('pageError')" :class="{active: currentPage == 'pageError'}">Error</li>


            <li @click="goto('choosecouponway')" :class="{active: currentPage == 'choosecouponway'}">How to print coupons?</li>
            <li @click="goto('printcoupon')" :class="{active: currentPage == 'printcoupon'}">Print my coupons</li>
            <li @click="goto('confirmationCoupon')" :class="{active: currentPage == 'confirmationCoupon'}">Confirmation Coupon</li>



          </ul>
        </fieldset>



        <fieldset>
          <legend>Logs</legend>
          <ul>
            <button @click="displayRequiredFieldsList()">Mandatory fields</button>
            <button @click="displayMetaFieldsList()">Meta fields</button>
            <button @click="showDataToBeSent()">Particip. data</button>
            <button @click="testAllLogs()">Custom logs</button>
            <button @click="displayFieldsValues()">FieldsValues</button>
            <button @click="displayFieldsValidity()">Fields Validity</button>
          </ul>
        </fieldset>

        <fieldset>
          <legend>Others</legend>
          <ul>
            <button @click="forceParticipation()">Force participation</button>
            <button @click="forceParticipationOwner()">Force part. owner</button>
            <button @click="unlockButtons()">Unlock buttons</button>
            <button @click="loadFor5Sec()">Load 3 sec</button>
            <button @click="fullTestPanelIsRequested = !fullTestPanelIsRequested; fullTestPanelHidden=true;">"Full Test" panel</button>
            <div>
              <button @click="displayModaleMessages('Error on demand', ['You wanted it, you have it!', 'Lorem ipsum'],'Do\'h!', 'alert')" style="width:49%; margin-right:1%;">Error</button>
              <button @click="displayModaleMessages('Info on demand', ['This information is free.'],`All's right`, 'info')" style="width:49%; margin-left:1%;">Info</button>
            </div>
            <div>
              <button @click="setLang(1)" style="width:49%; margin-right:1%;">=> FR</button>
              <button @click="setLang(2)" style="width:49%; margin-left:1%;">=> NL</button>
            </div>
            <button @click="allowFormToBeDisplayed = !allowFormToBeDisplayed">Toggle allow form</button>
          </ul>
        </fieldset>
      </div>

    </div>
  </div>
</template>





















































<!-- ================================================================================== -->

<script>
/* ======== OTHERS ======== */
import 'intl-tel-input/build/css/intlTelInput.css';
import config from "../config";

/* ======== COMPONENTS ======== */
import PageForm from '@/components/PageForm';
import ClaimBlocs from '@/components/ClaimBlocs';
// import CustomRadio from '@/components/CustomRadio';
import Spacer from '@/components/Spacer';
import Breadcrumbs from '@/components/Breadcrumbs';
import Toaster from '@/components/Toaster';
import FullTestPanel from '@/components/FullTestPanel';
import IconsSvg from '@/components/IconsSvg';
/* INPUTS (user TYPE answers) */
import InputRegex from "@/components/InputRegex";
import TextArea from "@/components/TextArea";

import MetaInput from "@/components/MetaInput";
import FreeInput from "@/components/FreeInput";

import CustomDatePicker from "@/components/CustomDatePicker";
//import PhoneInput from '@/components/PhoneInput';
import CustomInput from "@/components/CustomInput";
import FreeSlider from "@/components/FreeSlider";
import MetaSlider from "@/components/MetaSlider";
/* Other fields */
import CustomCheckbox from '@/components/CustomCheckbox';
import Upload from "@/components/Upload";
//import GoogleSelect from "@/components/GoogleSelect";
// EAN & Gtins
import EanInput from "@/components/EanInput";
import SelectGtin from "@/components/SelectGtin";

/* New Componants */

/* OK */
import SelectFree from "@/components/SelectFree";
import SelectApi from "@/components/SelectApi";
/* WIP */
import SelectMeta from "@/components/SelectMeta";
import PopupPG from '@/components/popupPG.vue';
import ParticipationCounter from '@/components/ParticipationCounter.vue';

/* TO BE REPLACED */
import Popitem from '@/components/Popitem';

/* OBSOLETES */
//* import CustomSelect from '@/components/CustomSelect';
//* import MetaCustomSelect from '@/components/MetaCustomSelect';



/* ======== MIXINS ======== */
import ActionDates from "../mixins/ActionDates";
import APICalls from "../mixins/APICalls";
import Cheat from "../mixins/Cheat";
import customFunctions from "../mixins/customFunctions";
import customLog from "../mixins/CustomLog";
import ecoupons from "../mixins/ecoupons";
import errorsManager from "../mixins/errorsManager";
import fieldsvalidator from "../mixins/fieldsvalidity";
import FL from "../mixins/floodlight";
import Functions from "../mixins/Functions";
//import GoogleAPI from "../mixins/GoogleAPI";
import metaFields from "../mixins/metaFields";
import NonAutomatedData from "../mixins/NonAutomatedData";
import openPDF from "../mixins/openpdf";
import pixel from "../mixins/pixel";
import sysinfos from "../mixins/sysinfos";

//import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css'

export default {
  name:"Form",

  mixins: [
    ActionDates,
    APICalls,
    Cheat,
    customFunctions,
    customLog,
    ecoupons,
    errorsManager,
    fieldsvalidator,
    FL,
    Functions,
    //GoogleAPI,
    metaFields,
    NonAutomatedData,
    openPDF,
    pixel,
    sysinfos,
  ],

  components: {
    /* Dropdown */
    Popitem,
    SelectApi,
    SelectFree,
    SelectGtin,
    SelectMeta,
    /* Inputs */
    CustomInput,
    FreeSlider,
    FreeInput,
    InputRegex,
    MetaInput,
    MetaSlider,
    //PhoneInput,
    TextArea,
    /* Others inputs */
    ClaimBlocs,
    CustomCheckbox,
    EanInput,
    //GoogleSelect,
    Upload,
    /* Tools */
    Breadcrumbs,
    FullTestPanel,
    IconsSvg,
    PageForm,
    ParticipationCounter,
    PopupPG,
    Spacer,
    Toaster,
    CustomDatePicker,
    //VueDatePicker
    /* Deprecated */
    // * FreeSelect // Obsolete => now use SelectFree
    // * CustomSelect // Obsolete => now use SelectApi
    // * MetaCustomSelect // Obsolete => new use SelectMeta
  },


  data () {
    return {
      participRefs: null,
      isAdminNavAllowed : config.isAdminNavAllowed,
      isMaintenanceCheckRequired : config.allowMaintenanceCheck,
      currentCodes: null,
      ownerData: {},
      popitemlist : [],
      reparticipation: false,
      metaIds: [],
      dateMessage: null,
      smsHelper: '', // used as modelValue. Maybe change name. …later.
      pendingParticipationSent: false,
      composedDataForParticipation: {},
      fullTestPanelHidden: true,
      fullTestPanelIsRequested: false,
      registrationRequired: true,
      isUploadPresent: false,
      listOfAllowedCountries: null,
      popUpIsVisible: false,
      previousActive: null,

      fieldsValues : {
        dataDestination: {
          api: null,
          meta: null,
          free: 3
        },
        bic:null,
        iban:null,
        idTitle: null,
        idGender: null,
        uniqueCode : null,
        firstname: null,
        lastname: null,
        email: null,

        /* NEW */
        /* default value = 5 */
        slider1: 5,
        slider2: 5,
        slider3: 5,
        /* end NEW */

        popitem: null, // * old one
        popitems:{
          /* Need as many popitem as user can declare */
          popitem1 : null,
          popitem2 : null,
          popitem3 : null,
        },
        gtin: null,
        gtinId: null,
        synonyms: [],

        demo: null,
        retailer: null,
        freeRetailer:null,
        purchaseDate: null,
        serialNumber: null,
        quantity: 0, // Must be 0 and not null to avoid a for-loop problem. => To be solved.
        language: null,
        birthdate : null,
        phone1: null,
        phone2: null,

        /* Adresse 1 */
        street1 : null,
        street2 : null,
        housenumber : null,
        boxnumber : null,
        postalcode : null,
        city : null,
        idcountrycode : null,

        /* Adresse 2 (1th time in 17017) */
        /* … */

        societe1 : null,
        societe2 : null,
        vatCountry: null,
        vatCode : null,
        confidentiality :null,
        cg : null,
        optin: 0, // default value 0 required
        survey:{
          /* Automatically fills after the load */
        }
      }
    };
  },


  computed: {
    shortListOfAllowedCountries(){
      let countryList = new Set();
      this.listOfAllowedCountries.forEach(country => {
        countryList.add(country.code.toLowerCase());
      });
      return Array.from(countryList);
    },

    placeholderSize(){
      if(window.innerWidth > 749 ){
        this.log(window.innerWidth);
        return "placeholder";
      }else{

        this.log("mobile window " + window.innerWidth);
        return "smallplaceholder";
      }
    }
  },



  methods: {

    activeMaintenancePage(){
      this.log("Request for maintenance page", "alert");
      this.goto('maintenance');
    },

    treatProofOfPurchaseDetailConsumer(category) {
      this.log("treatProofOfPurchaseDetailConsumer", 'group');

      this.bigOwnerData[category].forEach((pop, index) => {
        this.log(pop);
        this.fieldsValues.popitems["popitem" + (index+1)] = parseInt(this.bigOwnerData[category][index].idpopitem);
      });

      this.log('', 'groupEnd');
    },

    putDataIntoFieldsForAReparticipation(){
      let categories = Object.keys(this.bigOwnerData);
      this.log(categories);
      categories.forEach((category) => {

        this.log("Reading " + category);

        if(category === 'ParticipationMetaData'){
          this.log("Treating " + category);
          this.treatParticipationMetaData(category);
        }

        if(category === "ProofOfPurchaseHeaderConsumer"){
          this.log("Treating " + category);
          this.treatProofOfPurchaseHeaderConsumer(category);
        }

        if(category === "ProofOfPurchaseDetailConsumer" && this.bigOwnerData["ProofOfPurchaseDetailConsumer"]) {
          this.log("Treating " + category);
          this.treatProofOfPurchaseDetailConsumer(category);
        }

        if(category === 'ParticipationOwner'){
          this.log("Treating " + category);
          this.treatParticipationOwnerData(category);
        }

        if (category === "Participation"){
          this.log("Nothing to do (yet) inside " + category);
        }

      });
    },








    composeProofOfPurchaseDetailConsumer(popitemListLength){
      /* INFO ProofOfPurchaseDetailConsumer must be present if there is an info about product (popitem, serialNumber) otherwise, it must be left empty. So… */

      /* ==== POPITEM ==== */
      if (popitemListLength === 0){
        this.log("There is no POPItem in this action", 'info');
        /* We do nothing */
      }
      if (popitemListLength === 1){
        this.log("There is only ONE POPItem, use it's data", 'info');
        this.composedDataForParticipation.ProofOfPurchaseDetailConsumer = [{"idPOPItem": this.popitemlist[0].id, "quantity": 1}];
        /* POPDC is an object and that's all */
      }
      if (popitemListLength > 1){
        this.log("There is Multiple POPItems, so I must check what user CHOOSE in the form", 'info');
        this.composedDataForParticipation.ProofOfPurchaseDetailConsumer = this.returnsOnlyNonEmptyPopitems();
        /* POPDC is an array of possible items */
      }


      /* ==== EAN ==== */
      this.log("New installement for EAN, use with caution (in composeProofOfPurchaseDetailConsumer)", "red");
      // ! Cette formule suppose UN SEUL PRODUIT sélectionné.
      // ! Prévoir du changement en cas de produits multiples.
      if(this.fieldsValues.gtin){
        // Send the EAN itself
        this.composedDataForParticipation.ProofOfPurchaseDetailConsumer[0].itemSynonym = this.fieldsValues.gtin;
      }
      if(this.fieldsValues.gtinId){
        // Send the ID of the EAN in the list
        this.composedDataForParticipation.ProofOfPurchaseDetailConsumer[0].idItemSynonym = this.fieldsValues.gtinId;
      }


      /* ==== SERIAL NUMBER ==== */
      /* If there is a serialNumber */
      if (this.fieldsValues.serialNumber){
        /* If there is NO ProofOfPurchaseDetailConsumer, I create it as an empty array */
        if (!this.composedDataForParticipation.ProofOfPurchaseDetailConsumer){
          this.composedDataForParticipation.ProofOfPurchaseDetailConsumer = [];
        }
        /* In both case, I push the serialNumber value */
        this.composedDataForParticipation.ProofOfPurchaseDetailConsumer.push({serialNumber : this.fieldsValues.serialNumber});
      }

      /* ==== Quantity ==== */
      if (this.fieldsValues.quantity){
        this.composedDataForParticipation.ProofOfPurchaseDetailConsumer.push({quantity: this.fieldsValues.quantity});
      }

    },




    composeParticipationOwner(){
      let ParticipationOwner = {
        "idParticipationOwner": this.ownerData.id,
        "idTitle": this.fieldsValues.idTitle,
        "idGender": this.fieldsValues.idGender,
        "phoneNumber1": this.fieldsValues.phone1,
        "lastName": this.fieldsValues.lastname,
        "firstName": this.fieldsValues.firstname,
        "email": this.fieldsValues.email,
        "IBAN": this.fieldsValues.iban,
        "VATNumber": this.fieldsValues.VATNumber,
        "optin": this.fieldsValues.optin,
        "generalCondition": this.fieldsValues.cg,
        "idLanguageCode": this.getIdOfLanguage(),
        "idCountryCode": this.fieldsValues.idcountrycode,
        "companyName1": this.fieldsValues.companyName1,
        "street1": this.removeSpecialChars(this.fieldsValues.street1),
        "street2": this.removeSpecialChars(this.fieldsValues.street2),
        "houseNumber": this.fieldsValues.housenumber,
        "boxNumber": this.fieldsValues.boxnumber,
        "city": this.removeSpecialChars(this.fieldsValues.city),
        "postalCode": this.fieldsValues.postalcode,
        "keyAddress": this.fieldsValues.placeId,
      }
      return this.cleanNullValues(ParticipationOwner);
    },

    removeSpecialChars(str){
      if(str){
        this.log(`Removing special char from "${str}"`, "low");
        str = str.replaceAll('œ', 'oe');
        str = str.replaceAll('Œ', 'OE');
        this.log(`=> "${str}"`, "low");
      }
      return str;
    },

    getCurrentParticipRefCode(){
      // in case of a coupon action, the participationRef should be HCDPRINT else, it depends
      if(this.itsAnActionCoupon){
        return "HCDCPRINT";
      }else{
        return this.reparticipation ? 'REPART' : 'PART';
      }
    },

    getIdOfRepartCode(code){
      let res = this.participRefs.find(item => item.code === code).id;
      // store it
      this.$store.dispatch('setIdParticipationType', res);
      this.log("Should have stored the idParticipationType", 'wip');
      this.log(`The stored value should be ${res} and is ${this.$store.getters.getIdParticipationType}`);
      return res;
    },


    returnFieldsValue(){
      let theFieldsValues = {
        "Authorization" : this.oauth.token_type + ' ' + this.oauth.access_token,
        "idLeadManagement": this.lead.id,
        "isUploadPresent": this.isUploadPresent,

        "Participation": {
          "idParticipationType": this.getIdOfRepartCode(this.getCurrentParticipRefCode()),
          'fingerprint': this.$store.getters.getFingerprint,
        },

        "ParticipationOwner": this.composeParticipationOwner(),
        "ParticipationMetaData": this.composeParticipationMetaData(),
      };

      this.log(`The current type of participation id is: ${theFieldsValues.Participation.idParticipationType}. Check on table below it is correct`, 'red');
      this.log(this.participRefs, 'table');

      /* Must only add this if it contains at least one value */
      let ProofOfPurchaseHeaderConsumer = this.composeProofOfPurchaseHeaderConsumer();
      let size = Object.keys(ProofOfPurchaseHeaderConsumer).length;
      if (size > 0){
        theFieldsValues['ProofOfPurchaseHeaderConsumer'] = ProofOfPurchaseHeaderConsumer;
      }

      /* Last console check */
      this.log("The gathered field values are as such:", 'info');
      this.log(theFieldsValues);
      return theFieldsValues;
    },

    composeParticipationMetaData(){
      /* Empty array used if there is META but no "survey" */
      let survarray = []
      /* If there IS a survey, maybe the user answers it, or not */
      if (this.thereIsASurveyInThisAction){
        survarray.push({
          "idLeadTMD": 1,
          "idLeadTMDV": this.surveyMustBeFilled ? 1 : 2
          /*
          1: the user filled the survey, the coupon is sent in the mail
          2: the user skip the survey, the coupon is not sent in the mail
          */
        });
      }
      this.log("composeParticipationMetaData", "function");
      this.log("The array for survey is as such:");
      this.log(survarray);
      return survarray;
    },

    composeProofOfPurchaseHeaderConsumer(){
      let POPHC = {
        "idPOPRetailer": this.fieldsValues.retailer,
        "freeRetailerName" : this.fieldsValues.freeRetailer,
        "purchaseDate" : this.fieldsValues.purchaseDate,
        "uniqueCode" : this.fieldsValues.uniqueCode,
      };
      return this.cleanNullValues(POPHC);
    },

    composeData(){
      this.log("prep of the DATA before sending participation", 'title');

      /* Whitening */
      this.composedDataForParticipation = {};

      /* getting fields values */
      this.composedDataForParticipation = this.returnFieldsValue();

      // this.composedDataForParticipation.Participation.idParticipationType = 5;

      /* In case there is coupon(s) in this action */
      if(this.numberOfCouponsInThisAction > 0){
        this.log("There is coupons in this action", 'low');
        /* …obtain which coupon(s) user want */
        let consumerCouponsList = this.getRequestedCouponsList();
        if (consumerCouponsList.length > 0){
          this.log("User has selected coupons (or unique coupon)");
          this.composedDataForParticipation.ConsumerCoupon = consumerCouponsList;
        }
      }


      /* getting popitem(s) */
      let itemListLength = this.popitemlist.length;

      this.log('List of products','group')
      this.log({...this.popitemlist});
      this.log(`There are ${itemListLength} elements in the popitems list`);
      this.log('', 'groupEnd');

      this.composeProofOfPurchaseDetailConsumer(itemListLength);

      /* UTMs */
      this.composedDataForParticipation.Participation.UTMSource = this.utmSource;
      this.composedDataForParticipation.Participation.UTMMedium = this.utmMedium;
      this.composedDataForParticipation.Participation.UTMCampaign = this.utmCampaign;

      /* getting Metas */
      let nombreDeMetas = this.theMetaFieldsList.length;
      this.log("Details about meta-data", "group");
      this.log("There must be " + nombreDeMetas + " meta-data", "info");
      for (let i=0; i < nombreDeMetas; i++){
        this.checkMeta(this.metaIds[i], 'answersSQ' + (i+1));
      }
      this.log('', 'groupEnd');
      this.log(this.composedDataForParticipation);
      this.log("End of preview of data to be send", 'low');
    },



    checkMeta(questId, survId){
      this.log("Checking data for meta " + survId);
      let meta = this.theMetaFieldsList.filter(el => el.leadmanagementmetadata.id == questId);
      this.log(`Question ${questId} is: "${meta[0].leadmanagementmetadata.description}"`);
      this.log(`Answer is an id: ${this.fieldsValues.survey[survId]} of a string: ${this.fieldsValues.survey[survId + 'alt']}`);
      this.log("-".repeat(20));

      let newMetaToPushInParticipation = {};
      if(this.fieldsValues.survey[survId] !== '' && this.fieldsValues.survey[survId] !== null){
        newMetaToPushInParticipation["idLeadTMD"] = questId; // question ID
        newMetaToPushInParticipation["idLeadTMDV"] = parseInt(this.fieldsValues.survey[survId]); // Answer ID
      }
      if(this.fieldsValues.survey[survId + 'alt'] !== '' && this.fieldsValues.survey[survId + 'alt'] !== null){
        newMetaToPushInParticipation["answer"] = this.fieldsValues.survey[survId + 'alt']; // Free answer
      }
      if(Object.keys(newMetaToPushInParticipation).length > 0){
        this.composedDataForParticipation.ParticipationMetaData.push(newMetaToPushInParticipation);
      }
    },




    areConditionsToSendTheParticipationMet(){
      this.log("Check sending conditions", 'low');
      let conditionsAreMet = true;
      /* TODO Re-write conditions here */
      this.log(`Sending conditions: ${conditionsAreMet}`, 'wip');
      return conditionsAreMet;
    },


    whatIfIGetTheCountries(data){
      this.log("So, I got an allowed countries list.", 'low');
      this.listOfAllowedCountries = data;
      /* Refrehsing list in the country field */
      if(this.fieldsValues.idcountrycode){
        this.fieldsValues.descriptioncountrycode = this.convertCountryIdToDescription(this.fieldsValues.idcountrycode);
      }
    },

    whatIfICantGetTheCountries(error){
      this.log("I can't get the country list. What can I do now?",'todo');
      this.iGotThisErrorFromActionApi(error, this.$t('errors.countryListUnattainable')),
          console.log(error);
    },

    updateCountryDescription(){
      /* When locale change, the description of the country must be adapted manually */
      this.getFromAxios(this.callConfigs.getCountries);
    },

    togglePopup(name){
      console.log("toggle popup");
      this.currentPopUp = name;
      console.log("popup is " + this.popUpIsVisible);

      if(this.popUpIsVisible === false){
        this.previousActive = (document.activeElement || document.body);
      }else{
        console.group( "Restoring focus to previously-active element" );
        console.log( this.previousActive );
        console.groupEnd();

        this.previousActive.focus();
        this.previousActive = null;
      }

      this.popUpIsVisible = !this.popUpIsVisible;
      console.log(this.previousActive);

      this.$nextTick(() => {
        document.getElementsByClassName("popup-button")[0].focus();
      });

      console.log("popUpVisible is " + this.popUpIsVisible);
      document.body.classList.toggle('noscroll', this.popUpIsVisible);

    },

    closePopup(){
      this.popUpIsVisible = false;
      if(this.previousActive) {

        console.group( "Restoring focus to previously-active element" );
        console.log( this.previousActive );
        console.groupEnd();

        this.previousActive.focus();
        this.previousActive = null;

      }
    },

  }, /* End of methods */

  beforeMount(){
    // Clear console only in non-admin environment.
    if(!this.isAdminNavAllowed){
      console.clear();
    }else{
      this.log("FORM.VUE", "title");
    }

    this.consoleHdRef();
    this.consoleDateOfBuild();
    if(config.allowMaintenanceCheck){
      this.checkMaintenance();
    }else{
      this.log("No maintenance check required, proceeding…");
      this.startAction();
      this.getUserEnvironment(); // functions located in mixins/sysinfos.js
    }
  },

  mounted(){
    this.checkUrlForTestPanel();
  },

  watch: {
    '$store.getters.getLocale': function(newLocale, oldLocale){
      this.log(`${oldLocale} --> ${newLocale}`, 'low');
      if(this.registrationRequired){
        this.updateWhatsappCodes();
      }
      this.updateCountryDescription();
    },

    /* QUANTITY : Authorizing only a number between min and max */
    /* Uncomment this to allow the check */
    /*
    'fieldsValues.quantity': function(){
      let min = 1;
      let max = 100;
        if(this.fieldsValues.quantity){
        this.fieldsValues.quantity = Math.min(Math.max(parseInt(this.fieldsValues.quantity), min), max);
      }else{
        this.fieldsValues.quantity = 0;
      }
    },
    */

  },
}
</script>

<!-- ================================================================================== -->

<style lang="scss" scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity .2s, height .2s ease-in-out;
}
.fade-enter-from,
.fade-leave-to {
  opacity: 0;
  height:0;
}
.toggleButtonWrapper{
  text-align:center;
}
.toggleButton{
  position:initial;
  transform: translate(0%, 0%);
  font-size: 0.7em;
  margin: 20px auto;
  width: 400px;

  @media screen and (max-width: 1000px){
    white-space: nowrap;
  }
  @media screen and (max-width: 500px){

    width: 300px;
    padding: 1rem;
    line-height: 1rem;
    white-space: break-spaces;
  }
}

.popup-button{
  font-size:1.5em;
  background-color: #000;
  color: #fff;
  font-weight: bold;
  position: fixed;
  top: 10px;
  right: 10px;
  margin-top:1em;
  padding:0.5em 1em;
  cursor: pointer;
  text-align: center;
  z-index: 9999;
  transition:100ms;
  &:hover {
    background-color: #bfc3c6;
    transition:100ms;
  }
}

#overlay{
  position: fixed;
  height: 100vh;
  background-color: rgb(56 56 56 / 30%);
  width: 100vw;
  bottom: 0;
  left: 0;
  z-index: 100;
}

#popup{
  position: fixed;
  //padding: 1em;
  z-index:999999;
  font-size:1rem;
  line-height:1.2em;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 700px;
  height: 600px;
  background-color: #fff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  border-radius:20px;

  @media screen and (max-width: 698px){
    width: 300px;
  }
  @media screen and (max-width: 320px){
    height: 300px;
  }

}
#popup::-webkit-scrollbar {
  //margin-left: 10px;
}
</style>
